import React from "react";
import { compose } from "redux";

import withOtherAssetsDetails, {
  otherAssetPricesToSting
} from "../../../../../../hocs/withOtherAssetDetails";
import withTranslations from "../../../../../../hocs/withTranslations";
import Loader from "../../../../common/Loader";
import OtherAssetsEditOtherAsset from "./OtherAssetsEditOtherAsset";

const OtherAssetsEditOtherAssetWrapper = props => {
  if (props.otherAssetPricesData.loading) {
    return <Loader />;
  }

  return (
    <OtherAssetsEditOtherAsset
      {...props}
      otherAssetPrices={otherAssetPricesToSting(
        props.otherAssetPricesData.response.results
      )}
    />
  );
};

export default compose(
  withTranslations,
  withOtherAssetsDetails
)(OtherAssetsEditOtherAssetWrapper);
