import "../styles/default.scss";

import React, { useState } from "react";
import { withRouter } from "react-router";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import config from "../../../../../config";
import withPortfolioCashOperations from "../../../../../hocs/withPortfolioCashOperations";
import ContentSection from "../../ContentSection";
import PortfolioAddCashTransactionWrapper from "../../PortfolioAddCashTransactionWrapper";
import PortfolioAddOtherAssetTransactionWrapper from "../../PortfolioAddOtherAssetTransactionWrapper";
import PortfolioAddStockTransactionWrapper from "../../PortfolioAddStockTransactionWrapper";
import PortfolioCashOperationsList from "../../PortfolioCashOperationsList";
import PortfolioCashTable from "../../PortfolioCashTable";
import PortfolioOtherAssetsTable from "../../PortfolioOtherAssetsTable";
import PortfolioStocksTable from "../../PortfolioStocksTable";
import SquaberButton from "../../SquaberButton";
import SquaberLink from "../../SquaberLink";

const PortfolioComponents = ({
  translate,
  components,
  currency,
  portfolioId,
  portfolioData,
  portfolioCashTransactionsState,
  locale
}) => {
  let stockData = localStorage.getItem("stockToAddToPortfolio")
    ? JSON.parse(localStorage.getItem("stockToAddToPortfolio"))
    : null;

  const [currentStock, setCurrentStock] = useState(null);
  const [showCashOperationsList, setShowCashOperationsList] = useState(false);
  const [showAddStockTransaction, setShowAddStockTransaction] = useState(
    !!stockData
  );
  const [showAddCashTransaction, setShowAddCashTransaction] = useState(false);
  const [
    showAddOtherAssetTransaction,
    setShowAddOtherAssetTransaction
  ] = useState(false);

  const canAddStockTransactions =
    config.availableCurrencies.indexOf(portfolioData.currency) !== -1;

  return (
    <ContentSection className="portfolio-components">
      <h2 className="big-heading">{translate("Components")}</h2>
      <h2>{translate("Stocks")}</h2>
      {!canAddStockTransactions ? (
        <p>
          {translate(
            "Adding stock transactions is currently not supported for portfolios with this currency. We're sorry for inconvenience. We're working on bringing them back as soon as possible."
          )}
        </p>
      ) : (
        <React.Fragment>
          {components.stocks.length ? (
            <PortfolioStocksTable
              stocks={components.stocks}
              currency={currency}
              currentStock={currentStock}
              setCurrentStock={setCurrentStock}
            />
          ) : (
            <p>
              {translate(
                "There are no components in this portfolio. Add a transaction and start analyzing the selected stocks"
              )}
            </p>
          )}
          <div className="portfolio-components-buttons-wrapper">
            <SquaberButton
              onClick={() => {
                setShowAddStockTransaction(!showAddStockTransaction);
              }}
            >
              {translate("Add transaction")}
            </SquaberButton>
          </div>
          {showAddStockTransaction ? (
            <PortfolioAddStockTransactionWrapper
              portfolioId={portfolioId}
              portfolioData={portfolioData}
              setShowAddStockTransaction={setShowAddStockTransaction}
              defaultStockData={stockData}
            />
          ) : null}
        </React.Fragment>
      )}

      <div className={"portfolio-other-assets-wrapper"}>
        <div
          className={
            "portfolio-components-header-with-action-wrapper portfolio-other-assets-wrapper__header"
          }
        >
          <h2>{translate("Other assets")}</h2>
          <SquaberLink
            className={"portfolio-other-assets-wrapper__icon"}
            to={UrlProvider.getUrl("fe.otherAssetsSettings", {
              locale
            })}
          >
            <i className="far fa-cog" />
          </SquaberLink>
        </div>
        {components.assets.length ? (
          <PortfolioOtherAssetsTable
            portfolioData={portfolioData}
            portfolioId={portfolioId}
            currency={currency}
          />
        ) : (
          <p>
            {translate(
              "There are no other assets in this portfolio. Add an other asset"
            )}
          </p>
        )}
        <div className="portfolio-components-buttons-wrapper">
          <SquaberButton
            onClick={() => {
              setShowAddOtherAssetTransaction(prevState => !prevState);
            }}
          >
            {translate("Add other asset transaction")}
          </SquaberButton>
        </div>
        {showAddOtherAssetTransaction ? (
          <PortfolioAddOtherAssetTransactionWrapper
            portfolioId={portfolioId}
            portfolioData={portfolioData}
            setShowAddOtherAssetTransaction={setShowAddOtherAssetTransaction}
            currency={currency}
          />
        ) : null}
      </div>
      <h2>{translate("Cash")}</h2>
      <PortfolioCashTable
        cash={components.cash}
        dividends={components.dividends}
        portfolioCashTransactionsState={portfolioCashTransactionsState}
        startingCapital={portfolioData.starting_capital}
        currency={currency}
      />

      <div className="portfolio-components-buttons-wrapper">
        <SquaberButton
          color="secondary"
          onClick={() => {
            setShowCashOperationsList(!showCashOperationsList);
          }}
        >
          {translate("Operations list")}
        </SquaberButton>
        <SquaberButton
          onClick={() => {
            setShowAddCashTransaction(!showAddCashTransaction);
          }}
        >
          {translate("Add cash")}
        </SquaberButton>
      </div>

      {showCashOperationsList ? (
        <React.Fragment>
          <h2>{translate("Cash operations list")}</h2>
          <PortfolioCashOperationsList
            currency={currency}
            portfolioId={portfolioId}
            startingCapital={portfolioData.starting_capital}
            portfolioCashTransactionsState={portfolioCashTransactionsState}
          />
        </React.Fragment>
      ) : null}
      {showAddCashTransaction ? (
        <PortfolioAddCashTransactionWrapper
          portfolioId={portfolioId}
          setShowAddCashTransaction={setShowAddCashTransaction}
        />
      ) : null}
    </ContentSection>
  );
};

PortfolioComponents.propTypes = {};

export default compose(
  withPortfolioCashOperations,
  withRouter
)(PortfolioComponents);
