import { groupBy } from "lodash";
import moment from "moment-mini";
import { push } from "react-router-redux";
import { debounce, fork, put, select, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import { UrlProvider } from "../../../api/UrlProvider";
import { DIVIDEND_TRANSACTION_TYPE } from "../../../hocs/withPortfolioTransactionHistoryTable";
import AnalyticsEventTracker from "../../../lib/AnalyticsEventTracker";
import decisionModal from "../../../lib/decisionModal";
import { getIsWeb } from "../../../lib/platformHelper";
import OtherAssetEvent from "../../../lib/SalesManago/events/AddOtherAssetEvent";
import PortfolioCashOperationEvent from "../../../lib/SalesManago/events/PortfolioCashOperationEvent";
import PortfolioCreatedEvent from "../../../lib/SalesManago/events/PortfolioCreatedEvent";
import PortfolioDeleteEvent from "../../../lib/SalesManago/events/PortfolioDeleteEvent";
import PortfolioTransactionEvent from "../../../lib/SalesManago/events/PortfolioTransactionEvent";
import TransactionHelper from "../../../lib/UniversalTable/RendererHelpers/TransactionHelper";
import wait from "../../../lib/wait";
import { actions as actionInProgressActions } from "../actionInProgress";
import { actions as alertActions } from "../alerts";
import { getAuthToken } from "../auth/login/selector";
import { trackSMEvent } from "../salesManagoEventTracker";
import { addTagInNeedOfRefresh } from "../tags";
import { onFetchAllAvailableTags } from "../tags/saga";
import { initTagsServiceRoutine } from "../tagsService";
import { getLocale } from "../translations/selector";
import {
  addPortfolioCashTransactionRoutine,
  addPortfolioRoutine,
  addPortfolioTransactionRoutine,
  createNewOtherAssetRoutine,
  deleteTransactionRoutine,
  editOtherAssetRoutine,
  editPortfolioRoutine,
  getOtherAssetsRoutine,
  getPortfolioAssetsTransactionsRoutine,
  getPortfolioCashTransactionsRoutine,
  getPortfolioChartRoutine,
  getPortfolioRoutine,
  getPortfoliosListRoutine,
  getPortfoliosStockRoutine,
  getPortfolioStockTransactionsRoutine,
  getPortfolioTransactionHistoryRoutine,
  refreshPortfolioRoutine,
  removePortfolioRoutine
} from "./index";
import {
  getChartLastUsedType,
  getCurrentPortfolioState,
  getPortfolioHasErrors,
  getPortfoliosListState,
  getPortfolioTransactionHistory as getPortfolioTransactionHistorySelector
} from "./selector";

const portfolioReloadWaitDuration = 3000;

function* showRecalculationAlert() {
  yield put(actionInProgressActions.show("Portfolio data is recalculating."));
}

function* hideRecalculationAlert() {
  yield put(actionInProgressActions.hide());
}

function* getPortfolio({ payload: id }, isRefreshing = false) {
  const locale = yield select(getLocale);

  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.portfolio.singlePortfolio",
      variables: {
        id
      },
      skipErrorHandler: true
    });

    data.components.stocks = data.components.stocks.sort((a, b) =>
      a.ticker.localeCompare(b.ticker)
    );

    yield put(
      getPortfolioRoutine.success({
        data,
        isRefreshing
      })
    );

    yield getPortfolioCashTransactions();

    if (
      isRefreshing &&
      !data?.errors?.length &&
      data?.components?.cash === null
    ) {
      yield refreshPortfolio({}, true);
    } else {
      yield hideRecalculationAlert();
    }
  } catch (error) {
    yield put(getPortfolioRoutine.failure(error));
  }
}

function* refreshPortfolio(action, isNested = false) {
  const portfolioHasErrors = yield select(getPortfolioHasErrors);
  const currentPortfolioState = yield select(getCurrentPortfolioState);

  if (!currentPortfolioState.data) {
    yield wait(1000);
    return yield refreshPortfolio(action, isNested);
  }

  const {
    id: portfolioId,
    automated_stock_tag_id
  } = currentPortfolioState.data;

  yield put(addTagInNeedOfRefresh(automated_stock_tag_id));

  if (isNested || portfolioHasErrors) {
    yield showRecalculationAlert();
    yield wait(portfolioReloadWaitDuration);
  } else {
    yield wait(1000);
  }

  yield getPortfolio({ payload: portfolioId }, true);
  yield getPortfoliosList({}, true);
  yield getPortfolioChart(
    {
      payload: {
        portfolioId
      }
    },
    true
  );
}

function* getPortfolioChart({ payload }) {
  if (!payload.start) {
    payload = {
      type: yield select(getChartLastUsedType),
      ...payload
    };
  }

  try {
    const {
      data: { limits }
    } = yield ApiClient.get({
      urlPath: `app.portfolio.${payload.type}`,
      variables: {
        ...payload,
        start: 0,
        count: 15
      }
    });

    const { data } = yield ApiClient.get({
      urlPath: `app.portfolio.${payload.type}`,
      variables: {
        ...payload,
        start: limits[0],
        count: limits[1]
      }
    });

    const transformedData = data.data.map(element => ({
      time: moment(`${element[0]}Z`).format("YYYY-MM-DD"),
      value: element[1]
    }));

    yield put(
      getPortfolioChartRoutine.success({
        data: transformedData,
        lastUsedType: payload.type
      })
    );
  } catch (error) {
    yield put(getPortfolioChartRoutine.failure());
  }
}

function* getPortfolioTransactionHistory({ payload }) {
  const currentPortfolioTransactionHistory = yield select(
    getPortfolioTransactionHistorySelector
  );

  const { page, portfolioId } = payload;
  const limit = 20;

  let requestOptions = {};

  if (page && currentPortfolioTransactionHistory.data[page]) {
    requestOptions = {
      url: currentPortfolioTransactionHistory.data[page]
    };
  } else {
    requestOptions = {
      urlPath: "app.portfolio.transactionHistory",
      variables: {
        limit,
        portfolioId
      }
    };
  }

  try {
    const { data } = yield ApiClient.get(requestOptions);

    yield put(
      getPortfolioTransactionHistoryRoutine.success({
        data,
        page
      })
    );
  } catch (e) {
    yield put(getPortfolioTransactionHistoryRoutine.failure());
  }
}

function* deleteTransaction({ payload }) {
  if (!payload.portfolioId) {
    const currentPortfolioState = yield select(getCurrentPortfolioState);
    payload.portfolioId = currentPortfolioState.data.id;
  }

  const decision = yield decisionModal(
    "ConfirmModal",
    {
      title: "Are you sure?",
      message: "Do you really want to delete this transaction?"
    },
    ["confirm", "cancel"]
  );

  if (decision === "cancel") {
    return;
  }

  try {
    yield ApiClient.delete({
      urlPath: "app.portfolio.deleteTransaction",
      variables: {
        portfolioId: payload.portfolioId,
        transactionId: payload.transaction.id
      }
    });

    if (payload.transaction.transaction_type === "CashBalanceChange") {
      const smEvent = new PortfolioCashOperationEvent({
        portfolioId: payload.portfolioId,
        type: TransactionHelper.getTransactionTypeName(
          "cash",
          payload.transaction.type
        ).toLowerCase(),
        action: "deletion",
        operationDate: payload.transaction.date,
        cashAmount: payload.transaction.amount
      });

      yield put(trackSMEvent(smEvent));
    } else if (payload.transaction.transaction_type === "AssetTransaction") {
      const smEvent = new PortfolioTransactionEvent({
        portfolioId: payload.portfolioId,
        type: TransactionHelper.getTransactionTypeName(
          "stock",
          payload.transaction.type
        ).toLowerCase(),
        action: "deletion",
        assetName: payload.transaction.asset_name,
        transactionCommission: payload.transaction.commission,
        assetCount: payload.transaction.shares,
        transactionDate: payload.transaction.date,
        transactionPrice: payload.transaction.price
      });

      yield put(trackSMEvent(smEvent));
    } else if (
      payload.transaction.transaction_type === "Transaction" ||
      typeof payload.transaction.transaction_type === "undefined"
    ) {
      const smEvent = new PortfolioTransactionEvent({
        portfolioId: payload.portfolioId,
        type: TransactionHelper.getTransactionTypeName(
          "stock",
          payload.transaction.type
        ).toLowerCase(),
        action: "deletion",
        stockId: payload.transaction.stock,
        transactionCommission: payload.transaction.commission,
        stockCount: payload.transaction.shares,
        stockTicker: payload.transaction.ticker,
        transactionDate: payload.transaction.date,
        transactionPrice: payload.transaction.price
      });

      yield put(trackSMEvent(smEvent));
    }

    yield put(deleteTransactionRoutine.success(payload));
    yield refreshPortfolio();
  } catch (e) {}
}

function* createNewOtherAsset({ payload }) {
  try {
    const { data } = yield ApiClient.post({
      urlPath: "app.portfolio.addAsset",
      data: {
        name: payload[0].value,
        description: payload[1].value,
        currency: payload[2].value
      }
    });

    const smData = {
      id: data.id,
      action: "CREATION",
      name: data.name
    };

    const smEvent = new OtherAssetEvent(smData);
    yield put(trackSMEvent(smEvent));

    yield put(createNewOtherAssetRoutine.success(data));
    yield put(
      alertActions.addSuccessAlert({
        title: "Other asset created successfully"
      })
    );
  } catch (e) {
    console.error(e);
    yield put(createNewOtherAssetRoutine.failure());
    yield put(
      alertActions.addErrorAlert({ title: "Unexpected error occurred" })
    );
  }
}

function* getPortfoliosList(action, isRefreshing = false) {
  const authToken = yield select(getAuthToken);

  if (!authToken) {
    yield put(getPortfoliosListRoutine.failure("Missing auth token"));
    return;
  }

  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.portfolio.list",
      skipAccessCheck: true
    });

    if (isRefreshing) {
      let shouldRetry =
        data.portfolios
          .map(portfolio => portfolio.rate_of_return)
          .filter(value => value === null).length > 0 &&
        data.portfolios
          .map(portfolio => portfolio.errors)
          .filter(value => value.length > 0).length === 0;

      if (shouldRetry) {
        yield refreshPortfolio({}, true);
      } else {
        yield hideRecalculationAlert();
      }
    }

    yield put(getPortfoliosListRoutine.success(data));
  } catch (e) {
    yield put(getPortfoliosListRoutine.failure(e));
  }
}

function* getPortfoliosStock({ payload }) {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.portfolio.stock",
      variables: {
        stockId: payload?.stockId
      }
    });

    yield put(getPortfoliosStockRoutine.success(data));
  } catch (e) {
    console.error(e);
    yield put(getPortfoliosStockRoutine.failure(e));
  }
}

function* removePortfolio({ payload: id }) {
  const locale = yield select(getLocale);
  const currentPortfolioState = yield select(getCurrentPortfolioState);
  const portfoliosList = yield select(getPortfoliosListState);

  let currentPortfolioId;

  if (currentPortfolioState.data) {
    currentPortfolioId = currentPortfolioState.data.id;
  }

  const remainingPortfolios = portfoliosList.data.portfolios.filter(
    portfolio => portfolio.id !== id
  );
  const redirectAfterRemoving = getIsWeb() && id === currentPortfolioId;
  const redirectDestination = remainingPortfolios.length
    ? UrlProvider.getUrl("fe.portfolio", {
        locale,
        id: remainingPortfolios[0].id
      })
    : UrlProvider.getUrl("fe.portfoliosPageWithoutRedirect", {
        locale
      });

  const decision = yield decisionModal(
    "ConfirmModal",
    {
      title: "Are you sure?",
      message: "Are you sure you want to remove portfolio?"
    },
    ["confirm", "cancel"]
  );

  if (decision === "confirm") {
    try {
      yield ApiClient.delete({
        urlPath: "app.portfolio.singlePortfolio",
        variables: {
          id
        }
      });

      const smEvent = new PortfolioDeleteEvent({
        portfolioId: id
      });

      yield put(trackSMEvent(smEvent));

      yield put(getPortfoliosListRoutine());

      if (redirectAfterRemoving) {
        yield put(push(redirectDestination));
      }
    } catch (e) {}
  }
}

function* addPortfolio({ payload: { data, hideModal } }) {
  const locale = yield select(getLocale);

  try {
    const { data: responseData } = yield ApiClient.post({
      urlPath: "app.portfolio.list",
      data
    });

    hideModal();
    yield wait(500);

    yield put(addPortfolioRoutine.success());
    yield put(getPortfoliosListRoutine());
    yield put(initTagsServiceRoutine());
    yield onFetchAllAvailableTags();
    const smEvent = new PortfolioCreatedEvent(responseData);
    yield put(trackSMEvent(smEvent));

    if (getIsWeb()) {
      yield put(
        push(
          UrlProvider.getUrl("fe.portfolio", {
            locale,
            id: responseData.id
          })
        )
      );
    }

    yield AnalyticsEventTracker.trackPortfolioCreatedEvent();
  } catch (e) {
    if (e.request) {
      const response = JSON.parse(e.request.response);
      yield put(addPortfolioRoutine.failure(response.validation));
    }
  }
}

function* editPortfolio({ payload: { data, hideModal } }) {
  try {
    yield ApiClient.put({
      urlPath: "app.portfolio.singlePortfolio",
      data,
      variables: {
        id: data.id
      }
    });

    hideModal();
    yield wait(500);

    yield put(addPortfolioRoutine.success());
    yield refreshPortfolio();
  } catch (e) {
    let message;

    if (e.request) {
      const response = JSON.parse(e.request.response);
      const nonFieldErrors = response.validation.find(
        element => element.field === "non_field_errors"
      );
      message = nonFieldErrors ? nonFieldErrors.value : null;

      yield put(addPortfolioRoutine.failure(response.validation));
    }

    if (message) {
      yield put(
        alertActions.addErrorAlert({
          title: message
        })
      );
    }
  }
}

function* getPortfolioStockTransactions({ payload }) {
  const currentPortfolioState = yield select(getCurrentPortfolioState);
  const portfolioId = currentPortfolioState.data.id;

  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.portfolio.aggregated",
      variables: {
        portfolioId,
        ...payload
      }
    });

    const dividends = data.dividends.map(dividend => ({
      ...dividend,
      type: DIVIDEND_TRANSACTION_TYPE
    }));

    data.transactions = [...data.transactions, ...dividends].sort(
      (a, b) => moment(a.date).format("X") - moment(b.date).format("X")
    );

    yield put(getPortfolioStockTransactionsRoutine.success(data));
  } catch (e) {}
}

function* getOtherAssets() {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.portfolio.assets",
      variables: {
        limit: 99,
        offset: 0
      }
    });

    yield put(getOtherAssetsRoutine.success(data));
  } catch (e) {
    yield put(getOtherAssetsRoutine.failure(e));
  }
}
function* editOtherAsset({ payload }) {
  const { id, fields } = payload;

  try {
    const { data } = yield ApiClient.patch({
      urlPath: "app.portfolio.asset",
      variables: {
        id
      },
      data: {
        name: fields.find(({ name }) => name === "name").value,
        description: fields.find(({ name }) => name === "description").value
      }
    });

    yield ApiClient.post({
      urlPath: "app.portfolio.assetPricesUpload",
      variables: {
        id
      },
      data: {
        prices: fields.find(({ name }) => name === "prices").value
      }
    });

    yield put(
      alertActions.addSuccessAlert({
        title: "Other asset settings changed successfully"
      })
    );
    yield put(editOtherAssetRoutine.success(data));
  } catch (error) {
    yield put(
      alertActions.addErrorAlert({
        title: "Failed changed other asset settings"
      })
    );

    if (error?.response?.data?.prices) {
      try {
        yield put(
          editOtherAssetRoutine.failure([
            {
              field: "prices",
              value: Object.entries(error.response.data.prices)
                .map(([key, errors]) => `${key}: ${errors.join(", ")}`)
                .join("\n")
            }
          ])
        );
      } catch (e) {
        console.error(e);
      }
    } else {
      yield put(editOtherAssetRoutine.failure([]));
    }
  }
}

function* addPortfolioTransaction({ payload }) {
  const { requestData, hideForm } = payload;
  const currentPortfolioState = yield select(getCurrentPortfolioState);
  const { id: portfolioId } = currentPortfolioState.data;

  requestData.price = requestData.price.replace(",", ".");
  requestData.commission = requestData.commission.replace(",", ".");

  try {
    const { data } = yield ApiClient.post({
      urlPath: "app.portfolio.transaction",
      variables: {
        portfolioId
      },
      data: requestData
    });

    let smEvent = {};

    if (requestData.asset) {
      smEvent = new PortfolioTransactionEvent({
        portfolioId,
        type: TransactionHelper.getTransactionTypeName(
          "stock",
          requestData.type
        ).toLowerCase(),
        action: "addition",
        assetName: requestData.asset,
        transactionCommission: requestData.commission,
        assetCount: requestData.shares,
        transactionDate: requestData.date,
        transactionPrice: requestData.price
      });
    } else {
      smEvent = new PortfolioTransactionEvent({
        portfolioId,
        type: TransactionHelper.getTransactionTypeName(
          "stock",
          requestData.type
        ).toLowerCase(),
        action: "addition",
        stockId: requestData.stock,
        transactionCommission: requestData.commission,
        stockCount: requestData.shares,
        stockTicker: requestData.ticker,
        transactionDate: requestData.date,
        transactionPrice: requestData.price
      });
    }

    yield put(trackSMEvent(smEvent));

    if (hideForm) {
      hideForm();
    }

    yield wait(500);

    if (data.warnings) {
      for (let warning of data.warnings) {
        yield put(alertActions.addWarningAlert({ title: warning }));
      }
    }

    yield put(
      alertActions.addSuccessAlert({
        title: "Successfully added transaction"
      })
    );

    yield put(addPortfolioTransactionRoutine.success(data));

    yield put(
      getPortfolioTransactionHistoryRoutine.trigger({
        portfolioId
      })
    );

    yield refreshPortfolio();
    yield AnalyticsEventTracker.trackPortfolioTransactionEvent();
  } catch (error) {
    const response = error.request ? JSON.parse(error.request.response) : {};
    yield put(addPortfolioTransactionRoutine.failure(response.validation));
  }
}

function* getPortfolioCashTransactions() {
  const currentPortfolioState = yield select(getCurrentPortfolioState);

  if (!currentPortfolioState.data) {
    yield wait(1000);
    return yield getPortfolioCashTransactions();
  }

  const portfolioId = currentPortfolioState.data.id;

  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.portfolio.cashOperations",
      variables: {
        portfolioId
      }
    });

    data.results = data.results.sort(
      (a, b) => moment(a.date).format("X") - moment(b.date).format("X")
    );

    yield put(getPortfolioCashTransactionsRoutine.success(data));
  } catch (e) {
    yield put(getPortfolioCashTransactionsRoutine.failure());
  }
}

function* getPortfolioAssetsTransactions() {
  const currentPortfolioState = yield select(getCurrentPortfolioState);

  if (!currentPortfolioState.data) {
    yield wait(1000);
    return yield getPortfolioCashTransactions();
  }

  const portfolioId = currentPortfolioState.data.id;

  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.portfolio.assetsOperations",
      variables: {
        portfolioId
      }
    });

    if (!Array.isArray(data.results)) {
      throw Error("Returned results are not array");
    }

    const resultsGroupedByAssetId = groupBy(data.results, "asset_id");

    for (const key in resultsGroupedByAssetId) {
      resultsGroupedByAssetId[key] = resultsGroupedByAssetId[key].sort(
        (a, b) => moment(a.date).format("X") - moment(b.date).format("X")
      );
    }

    yield put(
      getPortfolioAssetsTransactionsRoutine.success(resultsGroupedByAssetId)
    );
  } catch (e) {
    yield put(getPortfolioAssetsTransactionsRoutine.failure());
  }
}

function* addPortfolioCashTransaction({ payload }) {
  const { hideForm, portfolioId, ...requestData } = payload;

  requestData.amount = requestData.amount.replace(",", ".");

  try {
    const { data } = yield ApiClient.post({
      urlPath: "app.portfolio.transaction",
      variables: {
        portfolioId
      },
      data: requestData
    });

    const smEvent = new PortfolioCashOperationEvent({
      portfolioId,
      type: TransactionHelper.getTransactionTypeName(
        "cash",
        requestData.type
      ).toLowerCase(),
      action: "addition",
      operationDate: requestData.date,
      cashAmount: requestData.amount
    });

    yield put(trackSMEvent(smEvent));

    hideForm();

    yield wait(500);

    yield put(
      alertActions.addSuccessAlert({
        title: "Successfully added cash transaction"
      })
    );
    yield put(addPortfolioCashTransactionRoutine.success(data));

    yield refreshPortfolio();
    yield put(
      getPortfolioTransactionHistoryRoutine({
        page: null,
        portfolioId
      })
    );
    yield put(getPortfolioCashTransactionsRoutine());
  } catch (error) {
    const response = error.request ? JSON.parse(error.request.response) : {};
    yield put(addPortfolioCashTransactionRoutine.failure(response.validation));
  }
}

function* watchGetPortfolios() {
  yield takeLatest(getPortfolioRoutine.TRIGGER, getPortfolio);
  yield debounce(1000, getPortfolioChartRoutine.TRIGGER, getPortfolioChart);
  yield takeLatest(
    getPortfolioTransactionHistoryRoutine.TRIGGER,
    getPortfolioTransactionHistory
  );
  yield takeLatest(deleteTransactionRoutine.TRIGGER, deleteTransaction);
  yield takeLatest(getPortfoliosListRoutine.TRIGGER, getPortfoliosList);
  yield takeLatest(getPortfoliosStockRoutine.TRIGGER, getPortfoliosStock);
  yield takeLatest(removePortfolioRoutine.TRIGGER, removePortfolio);
  yield takeLatest(addPortfolioRoutine.TRIGGER, addPortfolio);
  yield takeLatest(editPortfolioRoutine.TRIGGER, editPortfolio);
  yield takeLatest(
    getPortfolioStockTransactionsRoutine.TRIGGER,
    getPortfolioStockTransactions
  );
  yield takeLatest(
    addPortfolioTransactionRoutine.TRIGGER,
    addPortfolioTransaction
  );
  yield takeLatest(
    getPortfolioCashTransactionsRoutine.TRIGGER,
    getPortfolioCashTransactions
  );
  yield takeLatest(
    getPortfolioAssetsTransactionsRoutine.TRIGGER,
    getPortfolioAssetsTransactions
  );
  yield takeLatest(
    addPortfolioCashTransactionRoutine.TRIGGER,
    addPortfolioCashTransaction
  );
  yield takeLatest(refreshPortfolioRoutine.TRIGGER, refreshPortfolio);
  yield takeLatest(getOtherAssetsRoutine.TRIGGER, getOtherAssets);
  yield takeLatest(editOtherAssetRoutine.TRIGGER, editOtherAsset);
  yield takeLatest(createNewOtherAssetRoutine.TRIGGER, createNewOtherAsset);
}

export default [fork(watchGetPortfolios)];
