import React, { lazy, Suspense } from "react";

import config from "../../config";
import RegisterForm from "../components/auth/RegisterForm";
import Loader from "../components/common/Loader";
import LocalizedRouteRedirect from "../components/pages/LocalizedRouteRedirect";

// prettier-ignore
const HomePageRedirect = lazy(() => import("../components/pages/HomePageRedirect"));
const SocialRedirect = lazy(() => import("../components/pages/SocialRedirect"));
const LoginForm = lazy(() => import("../components/auth/LoginForm"));
const SearchPage = lazy(() => import("../components/pages/SearchPage"));
const StockPage = lazy(() => import("../components/pages/StockPage"));
const StocksTable = lazy(() => import("../components/pages/StocksTable"));
// prettier-ignore
const CryptocurrenciesTable = lazy(() => import("../components/pages/CryptocurrenciesTable"));
// prettier-ignore
const CurrenciesTable = lazy(() => import("../components/pages/CurrenciesTable"));
// prettier-ignore
const CommoditiesTable = lazy(() => import("../components/pages/CommoditiesTable"));
// prettier-ignore
const IndicesTable = lazy(() => import("../components/pages/IndicesTable"));
const PortfoliosPage = lazy(() => import("../components/pages/PortfoliosPage"));
// prettier-ignore
const ResetPasswordPage = lazy(() => import("../components/pages/ResetPasswordPage"));
const Page404 = lazy(() => import("../components/pages/Page404"));
// prettier-ignore
const MyStocksRedirect = lazy(() => import("../components/pages/MyStocksRedirect"));
// prettier-ignore
const IntercomPage = lazy(() => import("../components/pages/IntercomPage"));
const SettingsPage = lazy(() => import("../components/pages/SettingsPage"));
// prettier-ignore
const SettingsPageRedirect = lazy(() => import("../components/pages/SettingsPageRedirect"));
const AutoLogin = lazy(() => import("../components/auth/AutoLogin"));
const PaymentForm = lazy(() => import("../components/pages/PaymentForm"));
// prettier-ignore
const FullScreenChart = lazy(() => import("../components/pages/FullScreenChart"));
// prettier-ignore
const LightweightChartPage = lazy(() => import("../components/pages/LightweightChartPage"));
const Faq = lazy(() => import("../components/pages/Faq"));
const Regulations = lazy(() => import("../components/pages/Regulations"));
const CookiesPage = lazy(() => import("../components/pages/CookiesPage"));
const ContactPage = lazy(() => import("../components/pages/ContactPage"));
// prettier-ignore
const InterestingStocksPage = lazy(() => import("../components/pages/InterestingStocksPage"));
// prettier-ignore
const SquaberCommentsPage = lazy(() => import("../components/pages/SquaberCommentsPage"));
// prettier-ignore
const PaymentRedirectActionHandler = lazy(() => import("../components/pages/PaymentRedirectActionHandler"));
// prettier-ignore
const TurtleStrategyTablePage = lazy(() => import("../components/pages/TurtleStrategyTablePage"));
// prettier-ignore
const InsiderTransactionsSummary = lazy(() => import("../components/pages/InsiderTransactionsSummary"));
// prettier-ignore
const LongTermSignalPage = lazy(() => import("../components/pages/LongTermSignalPage"));
// prettier-ignore
const Desktop = lazy(() => import("../components/pages/Desktop"));
const LandingPage = lazy(() => import("../components/pages/LandingPage"));
// prettier-ignore
const AffiliatePageRedirect = lazy(() => import("../components/pages/AffiliatePageRedirect"));
// prettier-ignore
const UnsubscribePage = lazy(() => import("../components/pages/UnsubscribePage"));
// prettier-ignore
const UnsubscribeNotificationTypePage = lazy(() => import("../components/pages/UnsubscribeNotificationTypePage"));
// prettier-ignore
const UpcomingFeatures = lazy(() => import("../components/pages/UpcomingFeatures"));
// prettier-ignore
const ChangesHistory = lazy(() => import("../components/pages/ChangesHistory"));
// prettier-ignore
const SingleAnalysisPage = lazy(() => import("../components/pages/SingleAnalysisPage"));
// prettier-ignore
const ManualStrategyPage = lazy(() => import("../components/pages/ManualStrategyPage"));
// prettier-ignore
const GenericStaticPage = lazy(() => import("../components/pages/GenericStaticPage"));

const localizeRoutes = routes =>
  routes.map(route => ({
    ...route,
    path: `/:locale${route.path}`
  }));

const redirectToLocalizedRoutes = routes =>
  routes.map(route => ({
    ...route,
    component: LocalizedRouteRedirect,
    render: undefined
  }));

const localeRedirectRoutes = config.supportedLocales.map(locale => ({
  path: `/${locale}`,
  exact: true,
  render: props => (
    <Suspense fallback={<Loader />}>
      <HomePageRedirect {...props} />
    </Suspense>
  )
}));

const routesToLocalize = [];

if (config.vendor !== "expalerts") {
  routesToLocalize.push(
    {
      path: "/register",
      component: RegisterForm,
      exact: true
    },
    {
      path: "/payment-details/:offerId",
      render: props => (
        <Suspense fallback={<Loader />}>
          <PaymentForm {...props} />
        </Suspense>
      ),
      exact: true
    },
    {
      path: "/portfolios",
      render: props => (
        <Suspense fallback={<Loader />}>
          <PortfoliosPage {...props} />
        </Suspense>
      ),
      exact: true
    },
    {
      path: "/portfolios/:portfolioId",
      render: props => (
        <Suspense fallback={<Loader />}>
          <PortfoliosPage {...props} />
        </Suspense>
      ),
      exact: true
    },
    {
      path: "/interesting-stocks",
      render: props => (
        <Suspense fallback={<Loader />}>
          <InterestingStocksPage {...props} />
        </Suspense>
      ),
      exact: true
    },
    {
      path: "/manual-strategies/:id",
      render: props => (
        <Suspense fallback={<Loader />}>
          <ManualStrategyPage {...props} />
        </Suspense>
      ),
      exact: true
    },
    {
      path: "/analysis/:id",
      render: props => (
        <Suspense fallback={<Loader />}>
          <SingleAnalysisPage {...props} />
        </Suspense>
      ),
      exact: true
    },
    {
      path: "/analysis",
      render: props => (
        <Suspense fallback={<Loader />}>
          <SquaberCommentsPage {...props} />
        </Suspense>
      ),
      exact: true
    },
    {
      path: "/long-term-signals",
      render: props => (
        <Suspense fallback={<Loader />}>
          <LongTermSignalPage {...props} />
        </Suspense>
      ),
      exact: true
    }
  );
}

routesToLocalize.push(
  {
    path: "/search",
    render: props => (
      <Suspense fallback={<Loader />}>
        <SearchPage {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/login",
    render: props => (
      <Suspense fallback={<Loader />}>
        <LoginForm {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/my-stocks",
    render: props => (
      <Suspense fallback={<Loader />}>
        <MyStocksRedirect {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/fullscreenchart/:marketId/:stockId",
    render: props => (
      <Suspense fallback={<Loader />}>
        <FullScreenChart {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/lightweightchart/:stockId",
    render: props => (
      <Suspense fallback={<Loader />}>
        <LightweightChartPage {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/login/reset-password/:user_id/:hash",
    render: props => (
      <Suspense fallback={<Loader />}>
        <ResetPasswordPage {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/fullscreen-intercom",
    render: props => (
      <Suspense fallback={<Loader />}>
        <IntercomPage {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/settings",
    render: props => (
      <Suspense fallback={<Loader />}>
        <SettingsPageRedirect {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/settings/:settingsCategory",
    render: props => (
      <Suspense fallback={<Loader />}>
        <SettingsPage {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/settings/:settingsCategory/new",
    render: props => (
      <Suspense fallback={<Loader />}>
        <SettingsPage {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/settings/:settingsCategory/tag/:id",
    render: props => (
      <Suspense fallback={<Loader />}>
        <SettingsPage {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/settings/:settingsCategory/other-asset/:id",
    render: props => (
      <Suspense fallback={<Loader />}>
        <SettingsPage {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/settings/:settingsCategory/new-other-asset",
    render: props => (
      <Suspense fallback={<Loader />}>
        <SettingsPage {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/faq/:questionId?",
    render: props => (
      <Suspense fallback={<Loader />}>
        <Faq {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/regulations/:scrollToSection?",
    render: props => (
      <Suspense fallback={<Loader />}>
        <Regulations {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/cookies",
    render: props => (
      <Suspense fallback={<Loader />}>
        <CookiesPage {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/contact",
    render: props => (
      <Suspense fallback={<Loader />}>
        <ContactPage {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/trend-following-strategy-signal-list",
    render: props => (
      <Suspense fallback={<Loader />}>
        <TurtleStrategyTablePage {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/insider-transactions",
    render: props => (
      <Suspense fallback={<Loader />}>
        <InsiderTransactionsSummary {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/stocks-table",
    render: props => (
      <Suspense fallback={<Loader />}>
        <StocksTable {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/commodities",
    render: props => (
      <Suspense fallback={<Loader />}>
        <CommoditiesTable {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/currencies",
    render: props => (
      <Suspense fallback={<Loader />}>
        <CurrenciesTable {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/crypto",
    render: props => (
      <Suspense fallback={<Loader />}>
        <CryptocurrenciesTable {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/indices",
    render: props => (
      <Suspense fallback={<Loader />}>
        <IndicesTable {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/pulpit",
    render: props => (
      <Suspense fallback={<Loader />}>
        <Desktop {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/pulpit/:tagId",
    render: props => (
      <Suspense fallback={<Loader />}>
        <Desktop {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/affiliate",
    render: props => (
      <Suspense fallback={<Loader />}>
        <AffiliatePageRedirect {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/unsubscribe-electronic-communication/:userId/:hash",
    render: props => (
      <Suspense fallback={<Loader />}>
        <UnsubscribePage {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/unsubscribe/:notificationChannel/:notificationType/:userId/:hash",
    render: props => (
      <Suspense fallback={<Loader />}>
        <UnsubscribeNotificationTypePage {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/upcoming-features",
    render: props => (
      <Suspense fallback={<Loader />}>
        <UpcomingFeatures {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/changes-history",
    render: props => (
      <Suspense fallback={<Loader />}>
        <ChangesHistory {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/:marketId/:stockId/:section?",
    render: props => (
      <Suspense fallback={<Loader />}>
        <StockPage {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/:slug",
    render: props => (
      <Suspense fallback={<Loader />}>
        <GenericStaticPage {...props} />
      </Suspense>
    ),
    exact: true
  }
);

if (config.vendor === "expalerts") {
  routesToLocalize.push({
    path: "/auto-login",
    render: props => (
      <Suspense fallback={<Loader />}>
        <AutoLogin {...props} />
      </Suspense>
    ),
    exact: true
  });
}

export default [
  {
    path: "/",
    exact: true,
    render: props => (
      <Suspense fallback={<Loader />}>
        <HomePageRedirect {...props} />
      </Suspense>
    )
  },
  {
    path: "/social-redirect",
    render: props => (
      <Suspense fallback={<Loader />}>
        <SocialRedirect {...props} />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/payment-details",
    render: () => (
      <Suspense fallback={<Loader />}>
        <PaymentRedirectActionHandler />
      </Suspense>
    ),
    exact: true
  },
  {
    path: "/l/",
    exact: true,
    render: props => (
      <Suspense fallback={<Loader />}>
        <HomePageRedirect {...props} />
      </Suspense>
    )
  },
  {
    path: "/l/:slug",
    exact: true,
    render: props => (
      <Suspense fallback={<Loader />}>
        <LandingPage {...props} />
      </Suspense>
    )
  },
  ...localeRedirectRoutes,
  ...localizeRoutes(routesToLocalize),
  ...redirectToLocalizedRoutes(routesToLocalize),
  {
    render: props => (
      <Suspense fallback={<Loader />}>
        <Page404 {...props} />
      </Suspense>
    )
  }
];
