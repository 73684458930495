import Formsy from "formsy-react";
import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";

import { UrlProvider } from "../../../../../../../api/UrlProvider";
import withAddOtherAssetsDetailsForm from "../../../../../../../hocs/withAddOtherAssetDetailsForm";
import withCreateNewOtherAsset from "../../../../../../../hocs/withCreateNewOtherAsset";
import FormBuilderWeb from "../../../../../../../lib/FormBuilder/FormBuilderWeb";
import SquaberButton from "../../../../../common/SquaberButton";

const OtherAssetsAddNewOtherAsset = ({
  formBuilder,
  createNewOtherAsset,
  translate,
  history,
  locale
}) => (
  <Formsy
    onValidSubmit={() => {
      createNewOtherAsset(formBuilder.fields).then(() => {
        history.push(
          UrlProvider.getUrl("fe.otherAssetsSettings", {
            locale
          })
        );
      });
    }}
  >
    <FormBuilderWeb {...formBuilder} />
    <div className="text-right">
      <SquaberButton color="primary" type="submit">
        {translate("Add other asset")}
      </SquaberButton>
    </div>
  </Formsy>
);

OtherAssetsAddNewOtherAsset.propTypes = {};

export default compose(
  withAddOtherAssetsDetailsForm,
  withCreateNewOtherAsset,
  withRouter
)(OtherAssetsAddNewOtherAsset);
