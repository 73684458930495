const getTransactionHistory = translate => {
  return {
    name: translate("List of transactions in the portfolio"),
    tabs: [
      {
        id: "transaction",
        name: translate("Buying/selling stocks"),
        is_default: true,
        is_premium: false,
        column_pairs: [
          {
            csv_data_column_first: {
              label: translate("Ticker"),
              csv_name: "ticker",
              attribute_name: "ticker_as_link",
              filtering_attribute_name: "stock__ticker",
              sorting_attribute_name: "stock__ticker",
              values_type: "generated_in_frontend",
              filter_type: "search",
              filter_data: null,
              is_premium: false
            },
            csv_data_column_second: {
              label: translate("Transaction type"),
              csv_name: "transaction_type",
              attribute_name: "transaction_type",
              renderer_name: "transaction",
              filtering_attribute_name: "type",
              sorting_attribute_name: "type",
              values_type: "generated_in_frontend",
              filter_type: false,
              filter_data: null,
              is_premium: false
            }
          },
          {
            csv_data_column_first: {
              label: translate("Date"),
              csv_name: "date",
              attribute_name: "date",
              filtering_attribute_name: null,
              sorting_attribute_name: null,
              values_type: "date",
              filter_type: null,
              filter_data: null,
              is_premium: false
            },
            csv_data_column_second: {
              label: translate("Amount"),
              csv_name: "price",
              attribute_name: "price",
              filtering_attribute_name: null,
              sorting_attribute_name: null,
              values_type: "number",
              values_format: "number_default",
              filter_type: null,
              filter_data: null,
              is_premium: false
            }
          },
          {
            csv_data_column_first: {
              label: translate("basic-info-share_amount"),
              csv_name: "shares",
              attribute_name: "shares",
              filtering_attribute_name: null,
              sorting_attribute_name: null,
              values_type: "whole_number",
              filter_type: null,
              filter_data: null,
              is_premium: false
            },
            csv_data_column_second: {
              label: translate("Commission"),
              csv_name: "commission",
              attribute_name: "commission",
              filtering_attribute_name: null,
              sorting_attribute_name: null,
              values_type: "number",
              values_format: "number_default",
              filter_type: null,
              filter_data: null,
              is_premium: false
            }
          },
          {
            csv_data_column_first: {
              label: "",
              csv_name: "action",
              attribute_name: "action",
              values_type: "action",
              filter_type: null,
              filter_data: null,
              is_premium: false
            },
            csv_data_column_second: null
          }
        ]
      },
      {
        id: "asset_transaction",
        name: translate("Buy/sell other assets"),
        is_default: true,
        is_premium: false,
        column_pairs: [
          {
            csv_data_column_first: {
              label: translate("Name"),
              csv_name: "asset_name",
              attribute_name: "asset_name",
              filtering_attribute_name: "asset__name",
              sorting_attribute_name: "asset__name",
              values_type: "string",
              filter_type: "search",
              filter_data: null,
              is_premium: false
            },
            csv_data_column_second: {
              label: translate("Transaction type"),
              csv_name: "transaction_type",
              attribute_name: "transaction_type",
              renderer_name: "transaction",
              filtering_attribute_name: "type",
              sorting_attribute_name: "type",
              values_type: "generated_in_frontend",
              filter_type: false,
              filter_data: null,
              is_premium: false
            }
          },
          {
            csv_data_column_first: {
              label: translate("Date"),
              csv_name: "date",
              attribute_name: "date",
              filtering_attribute_name: null,
              sorting_attribute_name: null,
              values_type: "date",
              filter_type: null,
              filter_data: null,
              is_premium: false
            },
            csv_data_column_second: {
              label: translate("Amount"),
              csv_name: "price",
              attribute_name: "price",
              filtering_attribute_name: null,
              sorting_attribute_name: null,
              values_type: "number",
              values_format: "number_default",
              filter_type: null,
              filter_data: null,
              is_premium: false
            }
          },
          {
            csv_data_column_first: {
              label: translate("basic-info-share_amount"),
              csv_name: "shares",
              attribute_name: "shares",
              filtering_attribute_name: null,
              sorting_attribute_name: null,
              values_type: "whole_number",
              filter_type: null,
              filter_data: null,
              is_premium: false
            },
            csv_data_column_second: {
              label: translate("Transaction costs"),
              csv_name: "commission",
              attribute_name: "commission",
              filtering_attribute_name: null,
              sorting_attribute_name: null,
              values_type: "number",
              values_format: "number_default",
              filter_type: null,
              filter_data: null,
              is_premium: false
            }
          },
          {
            csv_data_column_first: {
              label: "",
              csv_name: "action",
              attribute_name: "action",
              values_type: "action",
              filter_type: null,
              filter_data: null,
              is_premium: false
            },
            csv_data_column_second: null
          }
        ]
      },
      {
        id: "cash_balance_change",
        name: translate("Cash transactions"),
        is_default: false,
        is_premium: false,
        column_pairs: [
          {
            csv_data_column_first: {
              label: translate("Transaction type"),
              csv_name: "transaction_type",
              attribute_name: "transaction_type",
              renderer_name: "cash_balance_change",
              filtering_attribute_name: "type",
              sorting_attribute_name: "type",
              values_type: "generated_in_frontend",
              filter_type: false,
              filter_data: null,
              is_premium: false
            },
            csv_data_column_second: null
          },
          {
            csv_data_column_first: {
              label: translate("Date"),
              csv_name: "date",
              attribute_name: "date",
              filtering_attribute_name: null,
              sorting_attribute_name: null,
              values_type: "date",
              filter_type: false,
              filter_data: null,
              is_premium: false
            },
            csv_data_column_second: null
          },
          {
            csv_data_column_first: {
              label: translate("Amount"),
              csv_name: "amount",
              attribute_name: "amount",
              filtering_attribute_name: null,
              sorting_attribute_name: null,
              values_type: "number",
              values_format: "number_default",
              filter_type: null,
              filter_data: null,
              is_premium: false
            },
            csv_data_column_second: null
          },
          {
            csv_data_column_first: {
              label: "",
              csv_name: "action",
              attribute_name: "action",
              values_type: "action",
              filter_type: null,
              filter_data: null,
              is_premium: false
            },
            csv_data_column_second: null
          }
        ]
      },
      {
        id: "dividend_payout",
        name: translate("Dividends"),
        is_default: false,
        is_premium: false,
        column_pairs: [
          {
            csv_data_column_first: {
              label: translate("Ticker"),
              csv_name: "ticker",
              attribute_name: "ticker_as_link",
              filtering_attribute_name: "stock__ticker",
              sorting_attribute_name: "stock__ticker",
              values_type: "generated_in_frontend",
              filter_type: "search",
              filter_data: null,
              is_premium: false
            },
            csv_data_column_second: null
          },
          {
            csv_data_column_first: {
              label: translate("Date"),
              csv_name: "date",
              attribute_name: "date",
              filtering_attribute_name: null,
              sorting_attribute_name: null,
              values_type: "date",
              filter_type: false,
              filter_data: null,
              is_premium: false
            },
            csv_data_column_second: null
          },
          {
            csv_data_column_first: {
              label: translate("Amount"),
              csv_name: "amount",
              attribute_name: "amount",
              filtering_attribute_name: null,
              sorting_attribute_name: null,
              values_type: "number",
              values_format: "number_default",
              filter_type: null,
              filter_data: null,
              is_premium: false
            },
            csv_data_column_second: null
          }
        ]
      }
    ]
  };
};

export default getTransactionHistory;
