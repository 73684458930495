import autoBind from "auto-bind";
import React from "react";

import connect from "../i18n/connect";
import { getOtherAssetsState } from "../redux/modules/portfolios/selector";

export default function withOtherAssetsSearchAutocomplete(WrappedComponent) {
  const mapStateToProps = state => ({
    otherAssets: getOtherAssetsState(state)
  });

  @connect(mapStateToProps)
  class WithOtherAssetsSearchAutocomplete extends React.Component {
    state = {
      inputValue: ""
    };

    constructor(props) {
      super(props);

      autoBind.react(this);
    }

    onInputChange(value) {
      this.setState({
        inputValue: value
      });
    }

    render() {
      const { otherAssets } = this.props;
      const { inputValue } = this.state;

      const otherAssetsWithQuery = otherAssets.loading
        ? {
            loading: true,
            error: null,
            data: null
          }
        : {
            loading: false,
            error: null,
            data: {
              results: otherAssets.data.results.filter(otherAsset => {
                if (otherAsset.description) {
                  return (
                    otherAsset.name
                      .toLowerCase()
                      .includes(inputValue?.toLowerCase?.()) ||
                    otherAsset.description
                      .toLowerCase()
                      .includes(inputValue?.toLowerCase?.())
                  );
                }

                return otherAsset.name
                  .toLowerCase()
                  .includes(inputValue?.toLowerCase?.());
              })
            }
          };

      return (
        <WrappedComponent
          {...this.props}
          otherAssetsWithQuery={otherAssetsWithQuery}
          onInputChange={this.onInputChange}
          inputValue={inputValue}
        />
      );
    }
  }

  return WithOtherAssetsSearchAutocomplete;
}
