import "../styles/default.scss";

import React from "react";

import withAutocomplete from "../../../../../hocs/withAutocomplete";
import FormsyComposedTextField from "../../../../../lib/FormBuilder/FormsyMaterialUI/FormsyComposedTextField";
import transformAutocompleteResults from "../../../../../lib/transformAutocompleteResults";

class SquaberAutocompleteWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      elementPosition: null,
      focused: false,
      inputValue: props.autocompleteValue || ""
    };

    this.setElementPosition = this.setElementPosition.bind(this);
  }

  componentDidMount() {
    this.setElementPosition();

    window.addEventListener("scroll", this.setElementPosition);
  }

  componentWillUnmount(): void {
    window.removeEventListener("scroll", this.setElementPosition);
  }

  setElementPosition() {
    this.setState({
      elementPosition: this.wrapperElement.getBoundingClientRect()
    });
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ): void {
    const { autocompleteValue } = this.props;
    const { inputValue } = this.state;

    if (
      prevProps.autocompleteValue !== autocompleteValue &&
      autocompleteValue !== inputValue
    ) {
      this.setInputValue(autocompleteValue);
    }

    if (prevProps.autocompleteValue && !autocompleteValue) {
      this.setInputValue("");
    }
  }

  setInputValue(inputValue) {
    this.setState({
      inputValue
    });
  }

  render() {
    const {
      translate,
      label,
      name,
      regularInput,
      getResults,
      autocompleteState,
      suggestionsCollectionResolver,
      setAutocompleteValue,
      setAutocompleteBlurred,
      validationErrors,
      validations,
      maxLength,
      required
    } = this.props;

    const { elementPosition, focused, inputValue } = this.state;

    const suggestionsCollection =
      typeof suggestionsCollectionResolver === "function"
        ? suggestionsCollectionResolver(autocompleteState, inputValue)
        : autocompleteState?.result?.stocks
        ? autocompleteState.result.stocks.map(transformAutocompleteResults)
        : [];

    return (
      <div
        className="squaber-autocomplete-wrapper"
        ref={element => {
          this.wrapperElement = element;
        }}
      >
        {regularInput ? (
          <input
            name={name}
            placeholder={translate(label)}
            value={inputValue}
            autoComplete="off"
            onChange={event => {
              const { value } = event.target;
              this.setInputValue(value);
              getResults(value);
            }}
            onFocus={() => {
              this.setElementPosition();
              this.setState({ focused: true });
              if (setAutocompleteBlurred) {
                setAutocompleteBlurred(false);
              }
            }}
            onBlur={() => {
              this.setState({ focused: false });
              if (setAutocompleteBlurred) {
                setAutocompleteBlurred(true);
              }
            }}
            onKeyDown={event => {
              if (
                (event.key === "Enter" && !inputValue) ||
                event.key === "Escape"
              ) {
                setAutocompleteValue("");
                this.setInputValue("");
              }
            }}
          />
        ) : (
          <FormsyComposedTextField
            required={required}
            name={name}
            label={label}
            maxLength={maxLength}
            validations={validations}
            onChange={value => {
              this.setInputValue(value);
              getResults(value);
            }}
            onFocus={() => {
              this.setElementPosition();
              this.setState({ focused: true });
              if (setAutocompleteBlurred) {
                setAutocompleteBlurred(false);
              }
            }}
            onBlur={() => {
              this.setState({ focused: false });
              if (setAutocompleteBlurred) {
                setAutocompleteBlurred(true);
              }
            }}
            value={inputValue}
            validationErrors={validationErrors}
          />
        )}
        {suggestionsCollection.length && focused && inputValue ? (
          <div className="squaber-autocomplete-options">
            <ul
              style={{
                // top boundary should begin where our reference element ends
                top: elementPosition.bottom,
                left: elementPosition.left
              }}
            >
              {suggestionsCollection.map((suggestion, index) => (
                <li
                  key={index}
                  onMouseDown={() => {
                    setAutocompleteValue({
                      autocompleteValue: suggestion.label,
                      stockData: suggestion.value,
                      price: suggestion.price
                    });
                  }}
                >
                  {suggestion.label}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withAutocomplete(SquaberAutocompleteWrapper);
