import { isEqual } from "lodash";
import React from "react";

import {
  prepareSectionIdList,
  prepareSectionSlugList
} from "../lib/prepareSectionsList";
import SEOHelper from "../lib/SEOHelper/SEOHelper";
import Loader from "../web/components/common/Loader";

export default function withStockSeoMetadata(WrappedComponent) {
  class WithStockSeoMetadata extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        slugList: [],
        seoData: null,
        preparedSlugList: [],
        preparedSectionIdList: []
      };
    }

    async fetchData() {
      const {
        match: {
          params: { marketId, stockId, section }
        },
        locale,
        authToken,
        marketTranslations
      } = this.props;

      const slugListPromise = SEOHelper.getStockSlugList(
        locale,
        authToken,
        marketId,
        marketTranslations
      );
      const slugList = await slugListPromise;
      const preparedSlugList = prepareSectionSlugList(slugList);
      const preparedSectionIdList = prepareSectionIdList(slugList);

      const seoData = await SEOHelper.getStockSeoMetadata(
        locale,
        authToken,
        marketId,
        stockId,
        preparedSectionIdList[section],
        marketTranslations
      );

      this.setState({
        slugList,
        seoData,
        preparedSlugList,
        preparedSectionIdList
      });
    }

    componentDidMount() {
      void this.fetchData();
    }

    componentDidUpdate(prevProps) {
      const {
        marketTranslations,
        match: {
          params: { marketId, stockId, section }
        }
      } = this.props;

      const {
        marketTranslations: prevMarketTranslations,
        match: {
          params: {
            marketId: prevMarketId,
            stockId: prevStockId,
            section: prevSection
          }
        }
      } = prevProps;

      if (
        !isEqual(marketTranslations, prevMarketTranslations) ||
        marketId !== prevMarketId ||
        stockId !== prevStockId ||
        section !== prevSection
      ) {
        void this.fetchData();
      }
    }

    render() {
      const {
        seoData,
        slugList,
        preparedSlugList,
        preparedSectionIdList
      } = this.state;

      if (seoData === null) {
        return <Loader />;
      }

      return (
        <WrappedComponent
          seoData={seoData}
          preparedSlugList={preparedSlugList}
          preparedSectionIdList={preparedSectionIdList}
          slugList={slugList}
          {...this.props}
        />
      );
    }
  }

  return WithStockSeoMetadata;
}
