import React from "react";
import * as AmplitudeBrowser from "@amplitude/analytics-browser";
import { withRouter } from "react-router-dom";
import getPageVisitEventData from "../../../../../lib/Amplitude/getPageVisitEventData";

@withRouter
class AmplitudePageViewHandler extends React.Component {
  componentDidMount() {
    const { location, match } = this.props;

    this.trackPageView(location, match);
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    const { location: prevLocation } = prevProps;
    const { location, match } = this.props;

    if (prevLocation.pathname !== location.pathname) {
      this.trackPageView(location, match);
    }
  }

  trackPageView(location) {
    const eventData = {
      ...getPageVisitEventData(location.pathname),
      url: window.location.href
    };

    AmplitudeBrowser.track("page_visit", eventData);
  }

  render() {
    return null;
  }
}

export default AmplitudePageViewHandler;
