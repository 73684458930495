import "../../PortfolioStocksTableRow/styles/default.scss";
import "../styles/default.scss";

import classNames from "classnames";
import React from "react";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withTranslations from "../../../../../hocs/withTranslations";
import SquaberLink from "../../SquaberLink";

const PortfolioOtherAssetsTableRow = ({
  asset,
  isCurrent,
  setCurrentOtherAsset,
  locale
}) => {
  return (
    <tr className="portfolio-stocks-table-row portfolio-other-assets-table-row">
      <td className="portfolio-other-assets-table-row__name">
        <SquaberLink
          to={UrlProvider.getUrl("fe.otherAssetSettings", {
            locale,
            id: asset.asset_id
          })}
          title={asset.asset_name}
        >
          {asset.asset_name}
        </SquaberLink>
      </td>
      <td>{asset.shares}</td>
      <td>{Number(asset.value_current).toFixed(2)}</td>
      <td>{Number(asset.value_buy).toFixed(2)}</td>
      <td>
        <span
          className="expand-stock"
          onClick={() => {
            if (isCurrent) {
              setCurrentOtherAsset(null);
            } else {
              setCurrentOtherAsset(asset);
            }
          }}
        >
          <i
            className={classNames({
              fal: true,
              "fa-ellipsis-v": !isCurrent,
              "fa-times": isCurrent
            })}
          />
        </span>
      </td>
    </tr>
  );
};

export default compose(withTranslations)(PortfolioOtherAssetsTableRow);
