import { Actions } from "react-native-router-flux";
import { takeLatest, fork, put, select } from "redux-saga/effects";
import reactNativeFirebaseAnalytics from "@react-native-firebase/analytics";
import * as AmplitudeNative from "@amplitude/analytics-react-native";
import { hide as hideActionInProgress } from "../actionInProgress/index";
import { fetchMarketingBarsRoutine as fetchMarketingBars } from "../marketingBars";
import { HANDLE_SCENE_CHANGE, setCurrentScene } from "./index";
import { UrlProvider } from "../../../api/UrlProvider";
import { getLocale } from "../translations/selector";
import { setShouldRenderFollowedStocks } from "../followed";
import getPageVisitEventData from "../../../lib/Amplitude/getPageVisitEventData";

function* onSceneChange({ payload }) {
  const { action } = payload;
  const locale = yield select(getLocale);

  const sceneToSet = Actions.currentScene;

  if (sceneToSet) {
    yield put(setCurrentScene(sceneToSet));

    if (["home", "stockPage"].indexOf(sceneToSet) === -1) {
      yield put(setShouldRenderFollowedStocks(false));
    }

    const sceneUrlParams = {
      ...(action.params && action.params.match
        ? action.params.match.params
        : {}),
      id: action.params ? action.params.id : undefined,
      locale
    };

    const sceneUrl = UrlProvider.getUrl(
      `nativeSceneUrl.${sceneToSet}`,
      sceneUrlParams,
      "/"
    );

    try {
      let shouldLogEvent = true;

      if (
        sceneToSet === "stockPage" &&
        (!sceneUrlParams.stockId || !sceneUrlParams.marketId)
      ) {
        shouldLogEvent = false;
      }

      if (shouldLogEvent) {
        yield reactNativeFirebaseAnalytics().logEvent("screen_view", { screen_name: sceneUrl });

        const amplitudeEventData = {
          ...getPageVisitEventData(sceneUrl),
          url: sceneUrl,
          page: sceneToSet
        };

        yield AmplitudeNative.track(
          "page_visit",
          amplitudeEventData
        );
      }
    } catch (e) {
      console.error(e);
    }
  }

  yield put(hideActionInProgress());
  yield put(fetchMarketingBars());
}

function* watchSceneChange() {
  yield takeLatest(HANDLE_SCENE_CHANGE, onSceneChange);
}

export default [fork(watchSceneChange)];
