module.exports = {
  title: "Squaber",
  vendor: "squaber",
  defaultLocale: "pl",
  supportedLocales: ["pl", "en"],
  tradingViewOptions: {
    logo: false
  },
  disclaimer: "https://squaber.com/static/invauth/disclaimer.pdf",
  termsAndConditionsUrl: "https://squaber.com/pl/regulations",
  privacyPolicyUrl: "https://squaber.com/pl/regulations/privacy-policy",
  gdprInfoUrl: "https://l.squaber.com/rozszerzona-klauzula-informacyjna/",
  defaultPremiumOfferUrl:
    "https://squaber.com/l/oferta-dostepu-squabera-premium",
  chartImageApiUrl: "https://squaber.com/:locale/api/",
  prodBaseUrl: "https://squaber.com/",
  cookieDomain: ".squaber.com",
  facebookCredentials: {
    appId: 1003458146779617,
    clientToken: "2add806a46942cfed3df4623681edb62"
  },
  defaultTagId: 37236,
  expoProjectNameForProxy: "@squaber/squaber-staging",
  expoSchemeFallback: "investio.squaber",
  followableLinkHostNames: [
    "squaber.com",
    "edukacja.squaber.com",
    "blog.squaber.com",
    "l.squaber.com",
    "s.squaber.com"
  ],
  internalLinkHostNames: ["squaber.com", "localhost", "s.squaber.com"]
};
