import React from "react";

import config from "../config";
import connect from "../i18n/connect";
import { getIsWeb } from "../lib/platformHelper";
import { clearEditOtherAssetValidationError } from "../redux/modules/portfolios";
import { getEditOtherAssetStateState } from "../redux/modules/portfolios/selector";
import withFormBuilder from "./withFormBuilder";
import withTranslations from "./withTranslations";

export default function withOtherAssetsDetailsForm(WrappedComponent) {
  const mapStateToProps = state => ({
    formState: getEditOtherAssetStateState(state)
  });

  const mapDispatchToProps = {
    clearValidationError: clearEditOtherAssetValidationError
  };

  @connect(mapStateToProps, mapDispatchToProps)
  @withTranslations
  @withFormBuilder(props => {
    const { otherAsset, otherAssetPrices } = props;

    return [
      {
        name: "name",
        label: "Other asset name",
        value: otherAsset?.name ?? "",
        type: "text",
        fullWidth: true,
        required: true,
        maxLength: 40,
        validations: {
          maxLength: 40
        },
        validationErrors: {
          isDefaultRequiredValue: "This field is required",
          maxLength: "Other asset name can't be longer than 40 characters"
        }
      },
      {
        name: "description",
        label: "Other asset description",
        value: otherAsset?.description ?? "",
        type: "text",
        fullWidth: true,
        required: false,
        multiline: true,
        validationErrors: {
          isDefaultRequiredValue: "This field is required"
        }
      },
      {
        name: "currency",
        label: "Currency",
        type: getIsWeb() ? "select" : "radio",
        options: config.availableCurrencies.map(currency => ({
          label: currency,
          value: currency
        })),
        value: otherAsset.currency || null,
        disabled: true,
        showInitialValue: true,
        required: true,
        validationErrors: {
          isDefaultRequiredValue: "This field is required"
        }
      },
      {
        name: "prices",
        label: "Other asset prices",
        value: otherAssetPrices,
        stackedLabel: true,
        type: "textarea",
        fullWidth: true
      }
    ];
  })
  class WithOtherAssetDetailsForm extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithOtherAssetDetailsForm;
}
