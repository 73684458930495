import React from "react";

import { connect } from "../i18n/connect";
import { getPortfolioStockTransactionsRoutine } from "../redux/modules/portfolios";
import { getPortfolioStockTransactionsState } from "../redux/modules/portfolios/selector";

export default function withPortfolioStockTransactionsList(WrappedComponent) {
  const mapStateToProps = state => ({
    portfolioStockTransactionsState: getPortfolioStockTransactionsState(state)
  });

  const mapDispatchToProps = {
    getPortfolioStockTransactions: getPortfolioStockTransactionsRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithPortfolioStockTransactionsList extends React.Component {
    componentDidMount(): void {
      const { ticker, market, getPortfolioStockTransactions } = this.props;

      getPortfolioStockTransactions({ ticker, market });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithPortfolioStockTransactionsList;
}
