import React from "react";
import { bindPromiseCreators } from "redux-saga-routines";

import { connect } from "../i18n/connect";
import {
  editOtherAssetPromiseCreator,
  getOtherAssetsRoutine,
  getPortfolioAssetsTransactionsRoutine
} from "../redux/modules/portfolios";
import {
  getOtherAssetsState,
  getPortfolioAssetsTransactionsState
} from "../redux/modules/portfolios/selector";

export default function withPortfolioOtherAssetsOperations({
  autoFetchOtherAssets
} = {}) {
  return WrappedComponent => {
    const mapStateToProps = state => ({
      portfolioOtherAssetsTransactionsState: getPortfolioAssetsTransactionsState(
        state
      ),
      otherAssets: getOtherAssetsState(state)
    });
    const mapDispatchToProps = {
      getPortfolioOtherAssetsTransactions: getPortfolioAssetsTransactionsRoutine,
      getOtherAssets: getOtherAssetsRoutine
    };

    const additionalMapDispatchToProps = dispatch => ({
      ...bindPromiseCreators(
        {
          editOtherAsset: editOtherAssetPromiseCreator
        },
        dispatch
      )
    });

    @connect(mapStateToProps, mapDispatchToProps)
    @connect(null, additionalMapDispatchToProps)
    class WithPortfolioOtherAssetsOperations extends React.Component {
      componentDidMount() {
        if (!autoFetchOtherAssets) {
          return;
        }

        const { getOtherAssets } = this.props;
        getOtherAssets();
      }
      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return WithPortfolioOtherAssetsOperations;
  };
}
