import React from "react";

import useGetRequest from "../hooks/useGetRequest";
import connect from "../i18n/connect";
import { getOtherAssetsState } from "../redux/modules/portfolios/selector";

const withOtherAssetsDetails = WrappedComponent => {
  const WithOtherAssetsDetails = props => {
    const otherAssetPricesData = useGetRequest({
      urlPath: "app.portfolio.assetPrices",
      variables: { id: props.otherAsset.id, limit: 999, offset: 0 },
      locale: props.locale
    });

    return (
      <WrappedComponent
        {...props}
        otherAssetPricesData={otherAssetPricesData}
      />
    );
  };

  const mapStateToProps = state => ({
    otherAssets: getOtherAssetsState(state)
  });

  return connect(mapStateToProps)(WithOtherAssetsDetails);
};

export default withOtherAssetsDetails;

export const otherAssetPricesToSting = prices => {
  return prices
    .map(({ datetime_utc, price }) => `${datetime_utc.split("T")[0]};${price}`)
    .join("\n");
};
