import "../../../TagsSettingsEditTag/styles/defatult.scss";

import Formsy from "formsy-react";
import React from "react";
import { withRouter } from "react-router";

import withOtherAssetsDetailsForm from "../../../../../../hocs/withOtherAssetDetailsForm";
import withTranslations from "../../../../../../hocs/withTranslations";
import FormBuilderWeb from "../../../../../../lib/FormBuilder/FormBuilderWeb";
import SquaberButton from "../../../../common/SquaberButton";

@withRouter
@withTranslations
@withOtherAssetsDetailsForm
class OtherAssetsSettingsEditOtherAsset extends React.Component {
  render() {
    const {
      translate,
      formBuilder,
      otherAsset,
      editOtherAsset,
      formState,
      clearValidationError
    } = this.props;

    return (
      <div className="tags-settings-edit-tag">
        <Formsy
          onValidSubmit={() => {
            editOtherAsset({
              id: otherAsset.id,
              fields: formBuilder.fields
            });
          }}
          validationErrors={formState.error}
        >
          <FormBuilderWeb
            {...formBuilder}
            validationErrors={formState.error}
            clearValidationError={clearValidationError}
          />
          <div className="text-right">
            <SquaberButton color="primary" type="submit">
              {translate("Apply")}
            </SquaberButton>
          </div>
        </Formsy>
      </div>
    );
  }
}

export default OtherAssetsSettingsEditOtherAsset;
