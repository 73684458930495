import autoBind from "auto-bind";
import moment from "moment-mini";
import React from "react";

import { connect } from "../i18n/connect";
import detectDateFormat from "../lib/detectDateFormat";
import { getIsWeb } from "../lib/platformHelper";
import {
  addPortfolioTransactionRoutine,
  clearAddTransactionValidationError,
  clearAllAddTransactionValidationErrors
} from "../redux/modules/portfolios";
import {
  getAddPortfolioStockTransactionState,
  getCurrentPortfolioState
} from "../redux/modules/portfolios/selector";
import withFormBuilder from "./withFormBuilder";
import withPortfolioTransactionHistoryTable, {
  DIVIDEND_TRANSACTION_TYPE
} from "./withPortfolioTransactionHistoryTable";

export default function withPortfolioAddOtherAssetTransaction(
  WrappedComponent
) {
  const mapStateToProps = state => ({
    currentPortfolio: getCurrentPortfolioState(state),
    addPortfolioStockTransactionState: getAddPortfolioStockTransactionState(
      state
    )
  });

  const mapDispatchToProps = {
    addPortfolioTransaction: addPortfolioTransactionRoutine,
    clearValidationError: clearAddTransactionValidationError,
    clearAllValidationError: clearAllAddTransactionValidationErrors
  };

  @connect(mapStateToProps, mapDispatchToProps)
  @withPortfolioTransactionHistoryTable
  @withFormBuilder(props => [
    {
      name: "type",
      label: "Transaction type",
      type: getIsWeb() ? "select" : "radio",
      value: "",
      options: Object.keys(props.transactionTypes.stock)
        .filter(key => Number(key) !== DIVIDEND_TRANSACTION_TYPE)
        .map(key => ({
          label: props.transactionTypes.stock[key],
          value: String(key)
        })),
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      }
    },
    {
      name: "date",
      label: "Date",
      type: "date",
      value: moment().format("DD.MM.YYYY"),
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      }
    },
    {
      name: "price",
      label: "Price",
      type: "text",
      value: props.quoteData
        ? String(Number(props.quoteData.close).toFixed(4))
        : "",
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      },
      inputMode: "numeric"
    },
    {
      name: "shares",
      label: "Stocks count",
      type: "text",
      value: "",
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      },
      inputMode: "numeric"
    },
    {
      name: "commission",
      label: "Transaction costs",
      type: "text",
      value: "",
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      },
      inputMode: "numeric"
    }
  ])
  class WithPortfolioAddOtherAssetTransaction extends React.Component {
    constructor(props) {
      super(props);

      autoBind.react(this);
    }

    addTransaction(data, hideForm) {
      const { setShowAddOtherAssetTransaction } = this.props;

      let requestData = {};

      data.formBuilder.fields.forEach(field => {
        if (field.name === "date") {
          requestData[field.name] = moment(
            field.value,
            detectDateFormat(field.value)
          ).format("YYYY-MM-DD");
        } else {
          requestData[field.name] = field.value;
        }
      });

      if (data?.formModel?.asset_id) {
        requestData.asset = data.formModel.asset_id;
      } else if (data?.formModel?.asset_name?.length > 0) {
        requestData.new_asset = data.formModel.asset_name;
      } else {
        console.error("Missing asset_name or asset_id");
        return;
      }

      this.props.addPortfolioTransaction({
        requestData,
        hideForm: () => {
          if (typeof hideForm === "function") {
            hideForm();
          } else if (typeof setShowAddOtherAssetTransaction === "function") {
            setShowAddOtherAssetTransaction(false);
          }
        }
      });
    }

    calculateCommission() {
      const { formBuilder } = this.props;

      formBuilder.handleChange(
        {
          name: "commission"
        },
        String(0)
      );
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          addTransaction={this.addTransaction}
          calculateCommission={this.calculateCommission}
        />
      );
    }
  }

  return WithPortfolioAddOtherAssetTransaction;
}
