import React from "react";
import { bindPromiseCreators } from "redux-saga-routines";

import connect from "../i18n/connect";
import { createNewOtherAssetPromiseCreator } from "../redux/modules/portfolios";

export default function withCreateNewOtherAsset(WrappedComponent) {
  const mapDispatchToProps = dispatch => ({
    ...bindPromiseCreators(
      { createNewOtherAsset: createNewOtherAssetPromiseCreator },
      dispatch
    )
  });

  @connect(null, mapDispatchToProps)
  class WithCreateNewOtherAsset extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithCreateNewOtherAsset;
}
