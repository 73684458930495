export default class AmplitudeHelper {
  static getIdentify(IdentifyClass, userDataToApply) {
    const identify = new IdentifyClass();

    for (let key in userDataToApply) {
      if (userDataToApply.hasOwnProperty(key)) {
        identify.set(key, userDataToApply[key]);
      }
    }

    return identify;
  }
}
