import "./FormsyComposedTextareaField.scss";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import Input from "@material-ui/core/TextareaAutosize";
import { withFormsy } from "formsy-react";
import React from "react";

import connect from "../../../i18n/connect";
import FormsyBase, { styles } from "./FormsyBase";

@connect()
@withFormsy
@withStyles(styles)
class FormsyComposedTextareaField extends FormsyBase {
  state = { blurred: false };

  showError() {
    const { isValid, isPristine, skipPristine, isFormSubmitted } = this.props;
    const { blurred } = this.state;
    return (
      !isValid &&
      (!isPristine || skipPristine) &&
      (blurred || skipPristine || isFormSubmitted)
    );
  }

  onBlur(event) {
    const { value } = this.props;
    const { blurred } = this.state;

    if (value) {
      this.handleChange(value.trim());
    }

    if (!blurred) {
      this.setState({ blurred: true });
    }

    if (this.props.onBlur && typeof this.props.onBlur === "function") {
      this.props.onBlur(event);
    }
  }

  render() {
    let {
      classes,
      name,
      label,
      fullWidth,
      multiline,
      rows,
      inputMode,
      errorMessage,
      value,
      translate,
      onFocus,
      disabled,
      maxLength
    } = this.props;

    const showError = this.showError();
    label = translate(label);

    return (
      <div className={"formsy-textarea-wrapper"}>
        <InputLabel htmlFor={name} shrink={true} error={showError}>
          {label}
        </InputLabel>
        <FormControl
          className={classes.formControl}
          error={showError}
          fullWidth={fullWidth}
          disabled={disabled}
        >
          <Input
            id={name}
            value={value || ""}
            type={"textarea"}
            onChange={event => this.handleChange(event.currentTarget.value)}
            multiline={multiline}
            rows={rows}
            onBlur={() => this.onBlur()}
            autoComplete={"off"}
            onFocus={onFocus}
            disabled={disabled}
            maxRows={999}
            maxLength={maxLength}
            inputMode={inputMode}
          />
          {showError ? (
            <FormHelperText>{translate(errorMessage)}</FormHelperText>
          ) : null}
        </FormControl>
      </div>
    );
  }
}

export default FormsyComposedTextareaField;
