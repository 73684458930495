import type { ContactEventData } from "../types/SalesManagoEventData.type";
import { BaseEvent } from "./BaseEvent";

interface OtherAssetEventMetaData {
  id: number;
  name: string;
}

class OtherAssetEvent extends BaseEvent {
  constructor(asset: OtherAssetEventMetaData) {
    super();
    this.asset = asset;
  }

  getEventName() {
    return "add_other_asset";
  }

  getEventTriggerName() {
    return "Creation of other asset from user settings";
  }

  getEventDetailsMatrix() {
    return `detail5=other asset id
detail6=creation
detail7=other asset name`;
  }

  getContactEventData(): ContactEventData {
    return {
      detail5: String(this.asset.id),
      detail6: "CREATION",
      detail7: this.asset.name
    };
  }
}
export default OtherAssetEvent;
