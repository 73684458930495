import "../styles/default.scss";

import React, { Suspense } from "react";
import { Redirect } from "react-router";

import SettingsPageAbstract from "../../../../../abstract/SettingsPageAbstract";
import { UrlProvider } from "../../../../../api/UrlProvider";
import withAddAlert from "../../../../../hocs/withAddAlert";
import withUserData from "../../../../../hocs/withUserData";
import Loader from "../../../common/Loader";
import NoAccessDisclaimer from "../../../common/NoAccessDisclaimer/modules/default";
import PageTitle from "../../../common/PageTitle";
import SettingsPageMenu from "../../../navigation/SettingsPageMenu";

const PersonalSettings = React.lazy(() =>
  import("../../../settings/PersonalSettings")
);
const MarketsSettings = React.lazy(() =>
  import("../../../settings/MarketsSettings")
);
const NotificationsSettings = React.lazy(() =>
  import("../../../settings/NotificationsSettings")
);
const PaymentsSettings = React.lazy(() =>
  import("../../../settings/PaymentsSettings")
);
const TagsSettings = React.lazy(() => import("../../../settings/TagsSettings"));
const OtherAssetsSettings = React.lazy(() =>
  import("../../../settings/OtherAssetsSettings")
);
const PartnershipSettings = React.lazy(() =>
  import("../../../settings/PartnershipSettings")
);
const AffiliateProgram = React.lazy(() =>
  import("../../../settings/AffiliateProgram")
);

@withUserData
@withAddAlert
class SettingsPage extends SettingsPageAbstract {
  settingsComponentsMap = {
    personal: PersonalSettings,
    markets: MarketsSettings,
    notifications: NotificationsSettings,
    payments: PaymentsSettings,
    tags: TagsSettings,
    "other-assets": OtherAssetsSettings,
    partnership: PartnershipSettings,
    affiliate: AffiliateProgram
  };

  render() {
    const {
      settingsCategories,
      groups,
      translate,
      match: {
        params: { settingsCategory }
      },
      locale,
      userData,
      addInfoAlert,
      userHasAccess
    } = this.props;

    if (!userData) {
      addInfoAlert({
        title: translate("You have to log in to continue")
      });
      return (
        <Redirect
          to={UrlProvider.getUrl("fe.loginWithRedirect", {
            locale,
            redirectUrl: window.location.pathname
          })}
        />
      );
    }

    const SettingsComponent = this.settingsComponentsMap[settingsCategory];

    const currentCategoryDetails = settingsCategories.find(
      category => category.name === settingsCategory
    );

    const showPremiumDisclaimer =
      currentCategoryDetails.requiresPremium && !userHasAccess;

    return (
      <div className="settings-page">
        <PageTitle title={translate("Settings")} />
        <div className="settings-page-sidebar">
          <SettingsPageMenu
            groups={groups}
            categories={settingsCategories}
            currentCategory={settingsCategory}
          />
        </div>
        <div className="settings-page-content">
          <Suspense fallback={<Loader />}>
            {showPremiumDisclaimer ? (
              <div className="content-section">
                <NoAccessDisclaimer />
              </div>
            ) : (
              <SettingsComponent />
            )}
          </Suspense>
        </div>
      </div>
    );
  }
}

export default SettingsPage;
